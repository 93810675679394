<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="余额ID 外键{zb_user_balance.id}" prop="userBalanceId" >-->
<!--        <a-input v-model="form.userBalanceId" placeholder="请输入余额ID 外键{zb_user_balance.id}" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="经纪人id外键{zb_user_broker.id}" prop="userBrokerId" >-->
<!--        <a-input v-model="form.userBrokerId" placeholder="请输入经纪人id外键{zb_user_broker.id}" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="操作前余额" prop="beforeBalance" >-->
<!--        <a-input v-model="form.beforeBalance" placeholder="请输入操作前余额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="变动余额" prop="balance" >-->
<!--        <a-input v-model="form.balance" placeholder="请输入变动余额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="操作后余额" prop="afterBalance" >-->
<!--        <a-input v-model="form.afterBalance" placeholder="请输入操作后余额" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="类型 0-冻结金额 1-订单返利  2-提现 3-提现失败" prop="type" >-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="方向 1-收入 2-支出" prop="direction" >-->
<!--        <a-input v-model="form.direction" placeholder="请输入方向 1-收入 2-支出" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="审核状态，0-待审核，1-已审核，-1审核驳回" prop="status" >
        <a-radio-group name="radioGroup" v-model="form.status" :default-value="0">
          <a-radio :value="0">待审核</a-radio>
          <a-radio :value="1">已通过</a-radio>
          <a-radio :value="-1">审核驳回</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-if="form.status == -1 " v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBalanceRecord, addBalanceRecord, updateBalanceRecord } from '@/api/user/balanceRecord'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userBalanceId: null,

        userBrokerId: null,

        beforeBalance: null,

        balance: null,

        afterBalance: null,

        type: null,

        direction: null,

        status: 0,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userBalanceId: [
          { required: true, message: '余额ID 外键{zb_user_balance.id}不能为空', trigger: 'blur' }
        ],
        userBrokerId: [
          { required: true, message: '经纪人id外键{zb_user_broker.id}不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '审核状态，0-待审核，1-已审核，-1审核驳回不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userBalanceId: null,
        userBrokerId: null,
        beforeBalance: null,
        balance: null,
        afterBalance: null,
        type: null,
        direction: null,
        status: 0,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBalanceRecord({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBalanceRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBalanceRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
