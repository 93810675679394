import request from '@/utils/request'


// 查询余额变动记录列表
export function listBalanceRecord(query) {
  return request({
    url: '/user/balanceRecord/list',
    method: 'get',
    params: query
  })
}

// 查询余额变动记录分页
export function pageBalanceRecord(query) {
  return request({
    url: '/user/balanceRecord/page',
    method: 'get',
    params: query
  })
}

// 查询余额变动记录详细
export function getBalanceRecord(data) {
  return request({
    url: '/user/balanceRecord/detail',
    method: 'get',
    params: data
  })
}

// 新增余额变动记录
export function addBalanceRecord(data) {
  return request({
    url: '/user/balanceRecord/add',
    method: 'post',
    data: data
  })
}

// 修改余额变动记录
export function updateBalanceRecord(data) {
  return request({
    url: '/user/balanceRecord/edit',
    method: 'post',
    data: data
  })
}

// 删除余额变动记录
export function delBalanceRecord(data) {
  return request({
    url: '/user/balanceRecord/delete',
    method: 'post',
    data: data
  })
}
